
.navbar-nav-show {
  @include media-breakpoint-down(md) {
    overflow: hidden;

    .navbar-menu-wrapper {
      transform: translateX(0);
    }

    .backdrop {
      opacity: 1;
      visibility: visible;
      left: calc(#{$width-sidebar} + 20px);
    }
  }
}

.navbar-header {
  align-items: stretch;
  height: $height-header-mobile;
  padding: 0;
  justify-content: flex-start;
  background-color: #fff;
  border-bottom: 1px solid $border-color;

  @include media-breakpoint-up(lg) {
    height: $height-header;
  }

  > .container {
    padding: 0;

    @media (max-width: 1139px) { max-width: none; }

    @media (min-width: 1140px) {
      .navbar-brand { padding-left: 0; }
      .navbar-right { padding-right: 0; }
    }
  }

  .navbar-brand {
    display: flex;
    align-items: center;
    padding-left: 15px;
    margin-right: 0;
    order: 1;

    @include media-breakpoint-up(lg) {
      width: $width-sidebar;
      padding: 0 0 0 20px;
    }
  }

  .burger-menu {
    margin-left: 18px;
    color: $color-brand-03;
    display: flex;
    align-items: center;

    @include media-breakpoint-up(sm) { margin-left: 20px; }
    @include media-breakpoint-up(lg) { display: none; }

    svg {
      width: 20px;
      height: 20px;
      stroke-width: 2.5px;
    }

    @include hover() { color: $color-brand-01; }
  }

  .btn-social {
    padding: 0;
    font-size: 18px;
    color: $color-text-02;
    min-height: inherit;
    display: none;

    @include media-breakpoint-up(xl) { display: inline-block; }

    @include hover-focus() {
      color: $color-text-01;

      &:first-of-type { color: $pink; }
      &:nth-of-type(3) { color: #00aced; }
    }

    + .btn-social { margin-left: 10px; }

    &.btn-for-dark {
      color: rgba(#fff, .5);
      @include hover-focus() { color: #fff; }
    }
  }

  .btn-buy {
    letter-spacing: 1px;
    padding: 0;
    line-height: 1.454;
    margin-left: 15px;
    display: flex;
    align-items: center;
    color: $color-brand-01;
    font-size: 11px;
    font-weight: $font-weight-semibold;
    text-transform: uppercase;

    @include media-breakpoint-up(lg) {
      min-height: inherit;
      padding: 7px 12px;
      color: $color-brand-01;
      border: 2px solid $color-brand-01;

      @include hover-focus() {
        background-color: $color-brand-01;
        color: #fff;
      }
    }

    .feather {
      width: 18px;
      height: 18px;
      margin-right: 0;

      @include media-breakpoint-up(lg) {
        width: 14px;
        height: 14px;
      }
    }

    span {
      margin-left: 8px;
      display: none;

      @include media-breakpoint-up(lg) { display: inline; }
    }

    &.btn-for-dark {
      border-color: #fff;
      color: #fff;
    }
  }

  .navbar-right {
    flex-basis: 0;
    padding: 0 15px;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    order: 2;

    @include media-breakpoint-up(sm) { padding-right: 20px; }
    @include media-breakpoint-up(lg) {
      order: 3;
      padding: 0 20px 0 0;
    }

    @include media-breakpoint-up(xl) {
      width: $width-sidebar;
    }

    .search-link {
      color: $body-color;
      @include hover-focus() { color: rgba($body-color, .8); }

      svg {
        width: 16px;
        height: 16px;
        stroke-width: 3px;

        @include media-breakpoint-up(sm) {
          width: 20px;
          height: 20px;
          stroke-width: 2.8px;
        }
      }
    }

    .dropdown {
      margin-left: 8px;

      @include media-breakpoint-up(sm) { margin-left: 15px; }

      &:last-child {
        margin-left: 15px;
        @include media-breakpoint-up(lg) { margin-left: 20px; }
        @include media-breakpoint-up(xl) { margin-left: 30px; }
      }
    }

    .dropdown-menu {
      border-top-width: 0;
      @include border-top-radius(0);
    }

    .dropdown-message {
      .dropdown-menu {
        margin-top: 17.5px;
        margin-right: -88px;

        &::before { right: 83px; }
        &::after { right: 84px; }

        @include media-breakpoint-up(sm) {
          margin-right: -20px;

          &::before { right: 20px; }
          &::after { right: 21px; }
        }

        @include media-breakpoint-up(lg) {
          margin-top: 19.5px;
        }
      }
    }

    .dropdown-notification {
      .dropdown-menu {
        margin-top: 17.5px;
        margin-right: -53px;

        &::before { right: 48px; }
        &::after { right: 49px; }

        @include media-breakpoint-up(sm) {
          margin-right: -20px;

          &::before { right: 20px; }
          &::after { right: 21px; }
        }

        @include media-breakpoint-up(lg) {
          margin-top: 19.5px;
        }
      }
    }

    .dropdown-profile {
      .dropdown-link .avatar {
        @include media-breakpoint-down(md) {
          width: 28px;
          height: 28px;
        }
      }

      .dropdown-menu {
        @include media-breakpoint-down(md) { margin-top: 14px; }
      }
    }
    
    .header-icon {
      width: 1.125rem;
      height: 1.125rem;
    }
  } // .navbar-right
} // .navbar-header

.header-icon {
  fill: currentColor;
  color: $fs-primary-01-50;
}

.navbar-header-fixed {
  @include media-breakpoint-up(lg) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
  }
}

.navbar-menu-wrapper {
  order: 3;
  width: calc(#{$width-sidebar} + 20px);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
  overflow: hidden;
  z-index: 1060;
  transform: translateX(calc((#{$width-sidebar} + 20px) * -1));
  @include transition(all 0.4s);

  @include media-breakpoint-up(lg) {
    width: auto;
    padding: 0 25px;
    position: relative;
    top: auto;
    left: auto;
    bottom: auto;
    background-color: transparent;
    overflow: visible;
    display: flex;
    justify-content: center;
    order: 2;
    transform: none;
    z-index: auto;
    flex: 1;
    
    .navbar-menu {
      flex-wrap: nowrap;
      align-items: stretch;
    }
    
    .nav-link {
      height: 100%;
    }
  }

  @include media-breakpoint-up(xl) { padding: 0 30px; }

  > .ps__rail-y { display: none; }
}

.navbar-menu-header {
  height: $height-header-mobile;
  padding: 0 15px 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $border-color;

  @include media-breakpoint-up(lg) { display: none; }

  .feather-x {
    width: 20px;
    height: 20px;
    stroke-width: 2.5px;
  }

  a:last-child {
    color: $gray-600;
    @include hover-focus() { color: $gray-900; }
  }
}

.navbar-menu {
  flex-direction: column;
  padding: 0;
  
  + .navbar-menu {
    margin-top: $spacer;
  }

  @include media-breakpoint-up(lg) {
    align-items: center;
    flex-direction: row;
    padding: 0;
    width: 100%;
    max-width: 780px;
    margin-top: -3px;
  }

  .nav-item {
    &.active {
      .nav-link {
        position: relative;
        color: $color-brand-01;

        &::before {
          content: '';
          position: absolute;
          top: calc(50% - 1px);
          left: 0;
          width: 10px;
          height: 2px;
          background-color: $color-brand-01;

          @include media-breakpoint-up(lg) { display: none; }
        }

        svg {
          color: $color-brand-01;
          fill: rgba($color-brand-01, .2);
        }
      }
    }

    &.with-sub {
      position: relative;

      .nav-link {
        @include caret;
        
        &::after {
          transition-property: transform, border-color, opacity;
          transition-duration: 0.2s;
          transition-timing-function: ease-in-out;
          margin-left: auto;
          border-top-width: $caret-width * 1.5;
          
          @include media-breakpoint-up(lg) {
            margin-left: $caret-spacing;
          }
        }
      }
      
      &.show {
        .nav-link {
          &::after {
            transform: rotate(90deg);
          }
        }
      }
    }

    &.show {
      .nav-link {
        background-color: $color-brand-01;
        color: $white;
        
        svg {
          color: inherit;
        }
      }
      
      .navbar-menu-sub { display: block; }
    }
  } // .nav-item

  .nav-link {
    @include transition($transition-base);
    
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 14px;
    font-weight: $font-weight-medium;
    padding: 1rem;
    color: $color-text-01;
    
    // needed when applied to buttons
    border-width: 0;
    background-color: transparent;
    
    &:focus {
      outline: none;
    }

    @include media-breakpoint-up(lg) {
      font-weight: $font-weight-medium;
      padding: 0 $spacer;
    }

    @include hover() {
      color: $color-brand-01;
      svg {
        color: inherit;
      }
    }

    svg {
      width: 1.25rem;
      height: 1.25rem;
      fill: currentColor;
      margin-right: 15px;
      color: $gray-600;

      @include media-breakpoint-up(lg) { display: none; }
    }
  } // .nav-link
  
  button.nav-link {
    width: 100%;
  }
  
  .nav-label {
    font-size: 10px;
    font-weight: 600;
    font-family: $font-family-system;
    letter-spacing: .7px;
    color: $color-text-03;
    text-transform: uppercase;
    display: block;
    margin-bottom: 15px;
  }
} // .navbar-menu

.navbar-menu-sub {
  position: relative;
  min-width: 200px;
  list-style: none;
  padding: 0 0 3px 0;
  margin-top: 0;
  z-index: 1000;
  display: none;

  @include media-breakpoint-up(lg) {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: $fs-secondary-02-25;
    padding: 0;
    margin-top: 0;
    margin-left: 0;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    position: relative;
    min-width: 150px;

    + ul {
      margin-top: 25px;

      @include media-breakpoint-up(lg) {
        margin-top: 0;
        margin-left: 25px;
        padding-left: 25px;
        border-left: 1px solid $border-color;
      }
    }
  }

  .nav-sub-item {
    + .nav-sub-item {
      border-top: 1px solid $fs-secondary-02-50;
    }
  }

  .nav-sub-link {
    color: fade-out($color-brand-03, 0.25);
    white-space: nowrap;
    font-size: $font-size-sm;
    display: flex;
    align-items: center;
    padding: 1rem;
    @include transition($transition-base);

    svg {
      width: 0.75rem;
      height: 0.75rem;
      margin-right: 15px;
      color: $gray-600;
      fill: currentColor;
      display: block;
    }

    .feather-bar-chart-2 { margin-left: -4px; }

    @include hover() {
      color: $white;
      background-color: $color-brand-02;
      
      svg,
      .feather {
        color: inherit;
      }
    }
  }

  // Inverted skin
  &.navbar-inverse {
    background-color: darken($gray-900, 5%);
    border-width: 0;

    &::before { border-bottom-color: darken($gray-900, 5%); }
    &::after { display: none; }

    .nav-label {
      color: rgba(#fff, .3);
      font-weight: $font-weight-medium;
    }

    ul + ul { border-left-color: rgba(#fff, .05); }

    .nav-sub-link {
      color: rgba(#fff, .5);
      font-weight: $font-weight-base;

      @include hover-focus() {
        color: #fff;

        svg,
        .feather {
          color: inherit;
        }
      }
    }
  }
}

.navbar-search {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  visibility: hidden;
  opacity: 0;
  @include transition($transition-base);

  &.visible {
    visibility: visible;
    opacity: 1;
  }
}

.navbar-search-header {
  height: $height-header-mobile;
  background-color: #fff;
  padding: 0 15px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid $border-color;

  @include media-breakpoint-up(sm) {
    padding: 0 20px;
    height: $height-header;
  }

  @include media-breakpoint-up(lg) { padding: 0 25px; }

  .form-control {
    padding: 0;
    font-size: 14px;
    font-weight: $font-weight-medium;
    color: $body-color;
    border-width: 0;
    background-color: transparent;

    @include media-breakpoint-up(lg) { font-size: 16px; }

    &::placeholder { font-weight: 400; }

    &:focus,
    &:active { box-shadow: none !important; }
  }

  .btn {
    padding: 0;

    svg {
      width: 20px;
      height: 20px;
      stroke-width: 2.8px;
    }
  }
}

.navbar-search-body {
  padding: 15px 15px 20px;
  background-color: #fff;
  font-size: 13px;

  @include media-breakpoint-up(lg) { padding: 25px 25px 30px; }

  ul {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;

    @include media-breakpoint-up(lg) { flex-direction: row; }
  }

  li {
    + li {
      margin-top: 2px;

      @include media-breakpoint-up(lg) {
        margin-top: 0;
        margin-left: 5px;
      }
    }
  }

  a {
    padding: 5px 10px;
    display: block;
    color: $color-text-02;
    @include border-radius();

    @include media-breakpoint-up(lg) {
      border: 1px solid $border-color;
    }

    @include hover-focus() {
      background-color: $color-ui-02;

      @include media-breakpoint-up(lg) {
        background-color: $color-ui-03;
        border-color: transparent;
      }
    }
  }
}
