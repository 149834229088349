
.table {
  th,
  td {
    padding: 8px 10px;
    line-height: 1.5;
  }

  thead th,
  tbody th { font-weight: $font-weight-medium; }

  thead th { border-bottom-width: 0; }
}

.table-striped tbody tr:nth-of-type(2n+1) {
  background-color: lighten($gray-200, 5%);
}

.table-hover tbody tr:hover {
  background-color: lighten($gray-200, 2%);
}

.table-sm {
  th,
  td { padding: 5px 10px; }
}

.thead-primary,
.thead-secondary,
.thead-success,
.thead-danger,
.thead-warning,
.thead-info,
.thead-light,
.thead-dark {
  color: #fff;

  th { border-top-width: 0; }

  + tbody tr:first-child {
    th,td { border-top-width: 0; }
  }
}

.thead-primary { background-color: $blue; }
.thead-secondary { background-color: $gray-600; }
.thead-success { background-color: $green; }
.thead-danger { background-color: $red; }
.thead-warning { background-color: $yellow; }
.thead-info { background-color: $cyan; }
.thead-dark { background-color: $gray-800; }
.thead-light {
  background-color: $border-color;
  color: $gray-700;
}

// Full color variation
.table-primary,
.table-secondary,
.table-success,
.table-danger,
.table-warning,
.table-info,
.table-light,
.table-dark {
  thead {
    color: #fff;
    th { border-top-width: 0; }
  }

  tbody tr {
    th,td { border-top-color: rgba(#fff, .45); }
    &:first-child {
      th,td { border-top-width: 0; }
    }
  }
}

.table-primary { @include table-variant($blue, lighten($blue, 48%)); }
.table-secondary { @include table-variant($gray-600, $gray-200); }
.table-success { @include table-variant($green, lighten($green, 56%)); }
.table-danger { @include table-variant($red, lighten($red, 45%)); }
.table-warning { @include table-variant($yellow, lighten($orange, 45%)); }
.table-info { @include table-variant($cyan, lighten($cyan, 55%)); }
.table-light { @include table-variant($gray-200, lighten($gray-200, 7%)); }
.table-dark { @include table-variant($gray-800, $gray-200); }

.table-light {
  thead { color: $body-color; }
}

.table-dark {
  tbody { color: $body-color; }
}
