
.badge {
  font-size: 10px;
  font-weight: 500;
  font-family: $font-family-system;
  padding: 3px 5px 4px;
  @include border-radius(3px);
}

.badge-light { background-color: $gray-300; }

.badge-pill {
  padding-left: 8px;
  padding-right: 8px;
}
