
.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-image: none;
  display: block;
  width: auto;
  height: auto;

  svg {
    width: 48px;
    height: 48px;
  }
}

.carousel-indicators li {
  width: 10px;
  height: 10px;
  @include border-radius(100%);
}
