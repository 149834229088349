// fantasticstay colors

$fs-primary-01: #19303e;
$fs-primary-01-75: #53646e;
$fs-primary-01-50: #8c979f;
$fs-primary-01-25: #c5cbcf;

$fs-primary-02: #00c141;
$fs-primary-02-75: #40d070;
$fs-primary-02-50: #80e0a0;
$fs-primary-02-25: #bfefcf;

$fs-primary-03: #009933;
$fs-primary-03-75: #40b366;
$fs-primary-03-50: #80cc99;
$fs-primary-03-25: #bfe5cc;

$fs-secondary-01: #9ac4ec;
$fs-secondary-01-75: #b3d3f1;
$fs-secondary-01-50: #cce1f5;
$fs-secondary-01-25: #e6f0fa;

$fs-secondary-02: #cbcbcb;
$fs-secondary-02-75: #d8d8d8;
$fs-secondary-02-50: #e5e5e5;
$fs-secondary-02-25: #f2f2f2;

$fs-secondary-03: #858585;
$fs-secondary-03-75: #a3a3a3;
$fs-secondary-03-50: #c2c2c2;
$fs-secondary-03-25: #e0e0e0;

// Brand Colors
$color-brand-01:                  $fs-primary-02;
$color-brand-02:                  $fs-primary-03;
$color-brand-03:                  $fs-primary-01;

// Inverse Colors
$color-inverse-01:                #09202E;
$color-inverse-02:                $fs-primary-01;

// Text Colors
$color-text-01:                   $color-inverse-01;
$color-text-02:                   $color-inverse-02;
$color-text-03:                   $fs-secondary-03;
$color-text-04:                   $fs-secondary-02; // figma secondary 2 

// UI Colors
$color-ui-01:                     $fs-secondary-02-25;
$color-ui-02:                     $fs-secondary-03-25;
$color-ui-03:                     $fs-primary-01-25;
$color-ui-04:                     $fs-primary-01-50;

// Height
$height-base:                     38px;
$height-header:                   3.75rem;
$height-header-mobile:            4.375rem;
$height-footer:                   46px;

// Width
$width-sidebar:                   16.5rem;

// Marker
$marker-icon-size:                16px;

// Fonts
$font-family-roboto:              "Roboto", sans-serif;
$font-family-system:              -apple-system, BlinkMacSystemFont, "Inter UI", Roboto, sans-serif;

$font-weight-medium:              500;
$font-weight-semibold:            600;
$font-weight-bold:                700;
