

.backdrop {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(darken($gray-900, 8%), .7);
  z-index: 40;
  @include transition(all 0.4s);

  &.show {
    visibility: visible;
    opacity: 1;
  }
}
